import React, { useCallback, useState } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import useMDContext from 'context/MDContext';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  noPadding: {
    padding: 0,
  },
}));

const RolloverConfirmation = () => {
  const classes = useStyles();
  const { rollover, setRollover, shouldResetFinancials, setShouldResetFinancials } = useMDContext();
  const [primaryCapTableChecked, setPrimaryCapTableChecked] = useState(rollover);
  const [resetFinancialPeriodsChecked, setResetFinancialPeriodsChecked] = useState(shouldResetFinancials);

  const handlePrimaryCapTableChange = useCallback(
    e => {
      setPrimaryCapTableChecked(e.target.checked);
      setRollover(e.target.checked);
    },
    [setRollover]
  );

  const handleResetFinancialPeriodsChange = useCallback(
    e => {
      setShouldResetFinancials(e.target.checked);
      setResetFinancialPeriodsChecked(e.target.checked);
    },
    [setShouldResetFinancials]
  );

  return (
    <>
      <Typography className={classes.title}>
        By default, the new measurement date will include the valuation, related cap tables, financials, notes, and
        referenced documents.
      </Typography>
      <div className={classes.marginTop}>
        <FormControlLabel
          control={<Checkbox checked={primaryCapTableChecked} onChange={handlePrimaryCapTableChange} />}
          label="Only rollover the primary cap table and financials, exclude the valuation."
        />
        <FormControlLabel
          control={<Checkbox checked={resetFinancialPeriodsChecked} onChange={handleResetFinancialPeriodsChange} />}
          label="Reset the LTM and NTM financial periods to the new measurement date."
        />
      </div>
    </>
  );
};
export default RolloverConfirmation;
